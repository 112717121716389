import KEY from './comp/key'
import { setUser, getMenus } from './pages/utils/commonService'

export const setRole = (params) => async dispatch => {
    try {
        const data = await setUser();
        dispatch({
            type: KEY.SET_USER,
            payload: data
        });
    } catch (e) {
        console.log(e.message)
    }
}

export const setLinkRouter = (params) => async dispatch => {
    try {
        const data = await getMenus();
        dispatch({
            type: KEY.SET_LINK,
            payload: data
        });
    } catch (e) {
        console.log(e.message)
    }
}