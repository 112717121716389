import axios from "./api";

export function getDataTable(params) {
  return axios.get("https://randomuser.me/api", { params });
}
export function getListProvinces(params) {
  return axios.get("/api/huyhq/get-province", { params });
}
export function getListAsm(params) {
  return axios.get("/api/salon/get-asm", { params });
}
export function getListSalonWithAsm(data) {
  return axios.post(
    `/api/salon/salon-with-province?staffId=${data.staffId}`,
    data.listIds
  );
}
export function getToken(params) {
  return axios.get("/login", { params });
}
export function getDataManager(params) {
  return axios.get("/admin/workremind/search", { params });
}
export function getReportForASM(params) {
  return axios.get("/admin/workremind/asm/report", { params });
}
export function getDepartment(params) {
  return axios.get("/api/salon/get-department", { params });
}
export function postNewRemind(data) {
  return axios.post("/admin/workremind/add", data);
}
export function getMenus(params) {
  return axios.get(
    // `${process.env.REACT_APP_AUTHOR}/api/permissions/menus?domain=${process.env.REACT_APP_WORKREMIND}`,
    `${process.env.REACT_APP_AUTHOR}/api/permissions/menus`,
    params
  );
}
export function getActions(params) {
  return axios.get(`${process.env.REACT_APP_AUTHOR}/api/permissions/actions`, {
    params,
  });
}
export function putUpdateRemind(data) {
  return axios.put("/admin/workremind/update", data);
}
export function getDetail(params) {
  return axios.get("/admin/workremind/detail/search", { params });
}
export function deleteWorkRemind(params) {
  return axios.delete("/admin/workremind/delete", { params });
}

export function getAllReport(params) {
  return axios.get("/admin/workremind/report/search", { params });
}

export function setUser() {
  return axios.get(`${process.env.REACT_APP_AUTHOR}/api/permissions/salons`);
}
