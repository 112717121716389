
import React, { useState, useEffect, Fragment } from 'react';
import 'antd/dist/antd.css';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { Layout, Menu, Icon, Avatar, Dropdown } from 'antd';
import logo from '../images/logo.png'
import { connect } from 'react-redux'

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const SiderMenu = (props) => {
    let history = useHistory();
    const [menus, setMenus] = useState([]);
    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const onChangeLink = (url) => {
        history.push(url);

        if (window.innerWidth < 767 && document.getElementsByClassName("ant-menu-submenu-open")[0]) {
            document.getElementsByClassName("ant-menu-submenu-inline")[0].classList.remove('ant-menu-submenu-open');
            document.getElementsByClassName("ant-menu-sub")[0].classList.add('ant-menu-hidden')
        }
    }

    useEffect(() => {
        const dataPush = [];
        for (let i = 0; i < props.menuActions.length; i += 1) {
            if (props.menuActions[i].meta.title === "Danh sách việc") {
                dataPush.push(props.menuActions[i]);
            }
        }
        setMenus(dataPush);
    }, [props.menuActions])

    const renderMenu = (item) => {
        if (item.pageId) {
            return (
                <Menu.Item key={item.meta.title}>
                    <div onClick={() => onChangeLink(item.pageId)}>
                        <Icon type={item.meta.icon ? item.meta.icon : "unordered-list"} />
                        <span>{item.meta.title}</span>
                    </div>
                </Menu.Item>
            )
        }
        else {
            return (
                <SubMenu key={item.meta.title}
                    title={<span><Icon type={item.meta.icon ? item.meta.icon : "unordered-list"} />
                        <span>{item.meta.title}</span> </span>}>
                    {item.children.map(element => {
                        return renderMenu(element)
                    })}
                </SubMenu>
            )
        }
    }

    return (
        <Fragment>
            <div className="pc">
                <Layout >

                    <Sider trigger={null} collapsible width={256} collapsed={collapsed}>

                        <div className={collapsed ? "logo logo-small" : "logo"}>
                            <img alt="" src={logo} /></div>
                        <Menu theme="light" mode="inline"
                            defaultSelectedKeys={['Home']}
                            defaultOpenKeys={['About']}
                            style={{ fontSize: '16px' }}
                        >
                            {menus.length !== 0 && menus.map((item, key) => {
                                return renderMenu(item)
                            })}
                        </Menu>

                    </Sider>
                    <Layout>
                        <Header className="header-layout">
                            <Icon
                                className="trigger"
                                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                                onClick={toggle}
                            />
                            <Dropdown overlay={Menu_Profile(history)} placement="bottomLeft">
                                <div className="about-user">
                                    <Avatar size="small" icon="user" />
                                    <div style={{ marginLeft: '5px' }}>{props.user.fullName}</div>
                                </div>
                            </Dropdown>
                        </Header>
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                background: '#fff',
                                minHeight: 280,
                            }}
                        >
                            {props.children}
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>ERP workremind ©2019 by 30shine Outside Team.</Footer>
                    </Layout>
                </Layout>
            </div>
            <div className="mb">
                <Header className="header-layout">
                    <Menu theme="light" mode="inline"
                        defaultSelectedKeys={['Home']}
                        defaultOpenKeys={['About']}
                        style={{ fontSize: '16px' }}
                    >
                        {menus.length !== 0 && menus.map((item, key) => {
                            return renderMenu(item)
                        })}
                    </Menu>
                    <Dropdown overlay={Menu_Profile(history)} placement="bottomLeft">
                        <div className="about-user">
                            <Avatar size="small" icon="user" />
                            <div style={{ marginLeft: '5px' }}>{props.user.fullName.split(' ')[props.user.fullName.split(' ').length - 1]}</div>
                        </div>
                    </Dropdown>
                </Header>

                <Content
                    style={{
                        padding: '12px 4px',
                        background: '#fff',
                    }}
                >
                    {props.children}
                </Content>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => {
    const { user, menus } = state;
    return { user, menuActions: menus }
}

export default connect(mapStateToProps)(SiderMenu);

const Menu_Profile = (history) => {
    const onChangeLogout = () => {
        // eslint-disable-next-line no-undef
        ssoLogout();
    }
    return (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
                    <Icon type="logout" style={{ fontSize: '12px', marginRight: "8px" }} ></Icon>Profile
                </a>
            </Menu.Item>
            <Menu.Item>
                <span onClick={onChangeLogout}>
                    <Icon type="profile" style={{ fontSize: '12px' }}></Icon>Logout
               </span>
            </Menu.Item>
        </Menu>
    )
};

