import React, { Suspense, useEffect } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { Skeleton, Result } from "antd";
import { setRole, setLinkRouter } from "../aciton";
import SiderMenu from "../comp/SiderMenu";
import { connect } from "react-redux";
import Home from "../pages/home/Home.js";

const DetailUser = React.lazy(() => import("../pages/user/DetailUser"));
const ManagerWorkRemind = React.lazy(() =>
  import("../pages/workremind/ManagerWorkRemind")
);
const ReportWorkRemind = React.lazy(() =>
  import("../pages/workremind/ReportWorkRemind")
);
const ReportWorkTodo = React.lazy(() =>
  import("../pages/workremind/ReportWorkToDo")
);
const ReportForASM = React.lazy(() =>
  import("../pages/workremind/ReportForASM")
);

const ProtectedPage = ({ menus }) => {
  const listPageIds = [];
  menus.forEach((element) => {
    element.children.lenght !== 0 &&
      element.children.forEach((el) => {
        listPageIds.push(el.pageId);
      });
  });
  return (
    <div id="content">
      <SiderMenu>
        {listPageIds.length > 0 ? (
          <Switch>
            <Route
              exact
              path="/detail"
              component={WaitingComponent(DetailUser)}
            />
            {listPageIds.length !== 0 &&
              listPageIds.includes("/managerWorkRemind") && (
                <Route
                  exact
                  path="/managerWorkRemind"
                  component={WaitingComponent(ManagerWorkRemind)}
                />
              )}
            {listPageIds.length !== 0 &&
              listPageIds.includes("/reportWorkRemind") && (
                <Route
                  exact
                  path="/reportWorkRemind"
                  component={WaitingComponent(ReportWorkRemind)}
                />
              )}
            {listPageIds.length !== 0 &&
              listPageIds.includes("/reportWorkTodo") && (
                <Route
                  exact
                  path="/reportWorkTodo"
                  component={WaitingComponent(ReportWorkTodo)}
                />
              )}
            {listPageIds.length !== 0 &&
              listPageIds.includes("/reportForASM") && (
                <Route
                  exact
                  path="/reportForASM"
                  component={WaitingComponent(ReportForASM)}
                />
              )}
            {/* <Route exact path="/reportForASM" component={WaitingComponent(ReportForASM)} /> */}
            <Route path="/dashboad" component={Home} />
            <Redirect to="/dashboad" />
          </Switch>
        ) : (
          <Result
            status="403"
            title="403"
            subTitle="Bạn không có quyền truy cập"
          />
        )}
      </SiderMenu>
    </div>
  );
};

const Routes = (props) => {
  useEffect(() => {
    const getToken = async () => {
      try {
        //eslint-disable-next-line no-undef
        const authenticated = await ssoLogin();
        localStorage.setItem(
          "AccessToken",
          "Bearer " + authenticated.AccessToken
        );
        localStorage.setItem(
          "RefreshToken",
          "Bearer " + authenticated.RefreshToken
        );
        props.setRole();
        props.setMenu();
      } catch (e) {
        console.log("errorAuthen", e);
      }
    };
    getToken();
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/">
          <ProtectedPage menus={props.menus} />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setRole: (value) => dispatch(setRole(value)),
    setMenu: (value) => dispatch(setLinkRouter(value)),
  };
};
const conGetMenus = (state) => {
  return {
    menus: state.menus,
    user: state.user,
  };
};
export default connect(conGetMenus, mapDispatchToProps)(Routes);

function PrivateRoute({ children, ...rest }) {
  const checkToken = localStorage.getItem("AccessToken");
  return <Route {...rest} render={({ location }) => checkToken && children} />;
}

function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<Skeleton paragraph={{ rows: 10 }} />}>
      <Component {...props} />
    </Suspense>
  );
}
