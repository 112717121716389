import React from 'react'
import { Row, Col, Descriptions, Skeleton, Tag } from 'antd';
import { getAllReport } from '../utils/commonService'
import { Bar, Pie } from 'ant-design-pro/lib/Charts';
import { connect } from 'react-redux';

class Home extends React.Component {
    state = {
        data: [],
        statusList: [],
        reportCountFinal: [],
        loading: false
    }
    componentDidMount() {
        this.showData();
    }

    showData = () => {
        this.setState({ loading: true })
        getAllReport({ ...this.params, staffId: this.props.userId }).then(data => {
            const _data = data.response.list.map((item, key) => { return { ...item, key: key } });
            if (data.message === "Success" && data.isSuccess) {
                this.setState({
                    statusList: data.response.statusList,
                    reportCountFinal: data.response.reportCountFinal,
                    data: _data,
                    loading: false
                });
            } else {
                this.setState({
                    data: [],
                    loading: false
                });
            }
        }).catch(() => {
            this.setState({ loading: false });
            console.log("error")
        });
    }

    render() {
        const { statusList, reportCountFinal, data, loading } = this.state;

        const dataStatus = [];
        for (let i = 0; i < statusList.length; i += 1) {
            dataStatus.push({
                x: statusList[i].label,
                y: statusList[i].value,
                type: statusList[i].type
            });
        }
        const salesData = [];
        for (let i = 0; i < reportCountFinal.length; i += 1) {
            salesData.push({
                x: reportCountFinal[i].key,
                y: reportCountFinal[i].value,
            });
        }

        return (
            <div>
                {!loading ?
                    <Row>
                        <Col span={15}>
                            <span style={{ fontWeight: 'bold', marginBottom: '15px' }}>Biểu đồ thống kê số lượng theo bộ phận</span>
                            <Bar height={400} data={salesData} />
                        </Col>
                        <Col span={8} style={{ marginRight: '10px' }}>
                            <Row>
                                <span style={{ fontWeight: 'bold', marginBottom: '15px' }}>Biểu đồ thống kê trạng thái công việc
                            </span>
                            </Row>
                            <Row>
                                <Pie
                                    data={dataStatus}
                                    height={366}
                                    colors={["rgb(82, 196, 26)", "rgb(16, 142, 233)", "rgb(255, 0, 0)"]}
                                />
                            </Row>
                            <Row>
                                <Descriptions>
                                    {data.length && dataStatus.map((item, key) => {
                                        return (
                                            <Descriptions.Item label={item.x} key={key} style={{ marginLeft: '10px' }}>
                                                {item.type === 1 ?
                                                    <Tag color="rgb(82, 196, 26)">{parseInt(item.y)}</Tag> :
                                                    (item.type === 3 ?
                                                        <Tag color="rgb(16, 142, 233)">{parseInt(item.y)}</Tag> :
                                                        <Tag color="rgb(255, 0, 0)">{parseInt(item.y)}</Tag>
                                                    )
                                                }
                                            </Descriptions.Item>
                                        )
                                    })}
                                </Descriptions>
                            </Row>
                        </Col>
                    </Row> : <Skeleton paragraph={{ rows: 5 }} />
                }
            </div >
        )
    }
}
const mapState = state => {
    const { user } = state;
    return { userId: user.userId }
}
export default connect(mapState)(Home)