import axios from 'axios';
import qs from 'qs'
// import * as ACITONS from '../actions'
// import { store } from '../App';
const http = require('http');
const https = require('https');
const instance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN,
    // baseURL: 'http://localhost:5000/',

    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
})

instance.interceptors.request.use(function (config) {
    config.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("AccessToken")
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    try {
        if (response.status !== 200)
            return Promise.reject(response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}, function (error) {
    return Promise.reject(error);
});

instance.defaults.paramsSerializer = params => {
    return qs.stringify(params, { indices: false })
};

export default instance;