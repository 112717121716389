import KEY from './comp/key'

const dfUser = {
    userId: 1113,
    fullName: 'It Test'
}

const initialState = {
    user: dfUser,
    menus: []
}

export const myReducer = (state, aciton) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (aciton.type) {
        case KEY.SET_USER:
            return { ...state, user: aciton.payload ? aciton.payload : dfUser }
        case KEY.SET_LINK:
            return { ...state, menus: aciton.payload ? aciton.payload : [] }
        default:
            return state;
    }
}
